import { debounce } from 'lodash';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useGetUserNftsV1, useLinkedAddress } from '@/api/api-hooks';
import filterApplied from '@/assets/images/filter-applied.svg';
import filter from '@/assets/images/filter.svg';
import { EWebsiteTabNames } from '@/bi/bi-constants';
import { collectiblesBIEvents } from '@/bi/events/collectibles-flow-bi-events';
import { viewWebsiteTabsBiEvents } from '@/bi/events/view-website-tabs-bi-events';
import utils, { isBrowser } from '@/common/utils';
import { IDashboardLayout } from '@/components/layout/dashboard-layout';
import AdvanceFilter from '@/features/collectibles-marketplace/components/advance-filter/advance-filter';
import QuickFilters from '@/features/collectibles-marketplace/components/quick-game-filters/quick-game-filters';
import useDeviceDetect from '@/features/collectibles-marketplace/deviceDetect.hook';
import { gamesAndDownloadUrls } from '@/features/collectibles-marketplace/helpers';

export interface IFiltersLayout extends IDashboardLayout, PropsWithChildren {
  showQuickFilters?: boolean;
  showAppliedFilters?: boolean;
  className?: string;
  children: React.ReactNode;
}

const FiltersLayout: FC<IFiltersLayout> = ({
  showQuickFilters,
  showAppliedFilters,
  className,
  children,
}) => {
  const { data: allNfts } = useGetUserNftsV1();
  const nftsDataSet = useRef<boolean>(false);
  const { isMobile } = useDeviceDetect();
  const advanceFilterRef = useRef<any>(null);
  const [nftCount, setNftCount] = useState(0);
  const [nftIds, setNftIds] = useState<any>('');
  const [allGameIds, setAllGameIds] = useState([]);
  const [isDomLoaded, setLoadDom] = useState(true);
  const [processedNfts, setProcessedNfts] = useState([]);
  const [checkboxFilters, setCheckboxfilter] = useState([]);
  const [deselectedFilters, setDeselectedFilters] = useState();
  const [clickedNftFilter, setFilterClick] = useState<any>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>([]);

  const [expandSideBar, setExpandSideBar] = useState(false);
  const { linkedAddress } = useLinkedAddress();
  const [clickedBlockchainFilter, setClickedBlockchainFilter] = useState([]);
  const [selectAllFilter, setSelectAllFilter] = useState(false);
  const [copyProcessedNfts, setCopyProcessedNfts] = useState([]);

  const [selectedGameId, setSelectedGameId] = useState('');
  const [activeQuickFilter, setActiveQuickFilter] = useState([]);

  //TODO: Remove after api inegration Battel champs
  const filters = [
    // { name: "All", id: 'all' },
    { name: 'Tennis Champs', id: 'onjoyride.tennischamps' },
  ];
  const gameIds = ['onjoyride.tennischamps'];

  // create an event listener
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setExpandSideBar(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (!isBrowser()) {
        return;
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openSidebar = () => {
    const expand = !expandSideBar;
    setExpandSideBar(expand);
  };

  const openSidebarWithBiEvent = () => {
    openSidebar();
    collectiblesBIEvents.userClicksOnMoreGamesOrFilters(
      'filter',
      nftIds,
      '' as any,
      nftCount,
      0,
    );
  };

  const getNfts = () => {
    // allNfts
    if (allNfts.length === 0) {
      return;
    }
    // makes sure this only runs once
    nftsDataSet.current = true;
    try {
      handelMultipleChamps(allNfts);
      setLoadDom(true);
    } catch (e) {
      // BI event maybe
      console.error('getNfts error');
    }
  };

  const getDownloadUrlOfGame = (gameId: any) => {
    const game = gamesAndDownloadUrls.find((item) => item.id === gameId);
    return game?.url || '';
  };

  function handelMultipleChamps(champs: any) {
    const allGames: any[] = [];
    // get all unique game-ids
    gameIds.forEach((id) => {
      const nfts =
        champs?.filter(
          (item: any) => item.characterType === 'NFT' && item?.appId === id,
        ) || [];
      const mintPass =
        champs?.filter(
          (item: any) =>
            item.characterType === 'MINT_PASS' && item?.appId === id,
        ) || [];
      allGames.push({
        appId: id,
        nfts,
        mintPass,
        url: getDownloadUrlOfGame(id),
      });
    });
    utils.sortGames(allGames);
    const games: any = [];
    const nftIds: any = [];
    let nftsCount = 0;
    allGames.forEach((game) => {
      games.push(utils.getGameName(game.appId) + '(' + game.nfts.length + ')');
      game.nfts.forEach((nft: any) => {
        nftIds.push(
          nft.nft.nftIndex +
            '_' +
            nft.nft.contractId +
            '_' +
            nft.nft.blockchain,
        );
      });
      setNftIds(nftIds.toString());
      nftsCount = nftsCount + game.nfts.length;
      setNftCount(nftsCount);
    });
    viewWebsiteTabsBiEvents.userIsOnSomeTab(
      EWebsiteTabNames.COLLECTIBLES,
      games.toString(),
      nftIds.toString(),
      null as any,
      nftsCount,
      0,
    );
    setProcessedNfts(allGames as any);
    setCopyProcessedNfts(allGames as any);
  }

  const handelGameFilter = (gameId: any) => {
    let allGameIds: any = [];
    const allNftsFromState = [...copyProcessedNfts];
    allGameIds = [...clickedNftFilter];
    // on selecting all
    if (
      !selectAllFilter &&
      gameId === 'all' &&
      !clickedNftFilter.includes('all')
    ) {
      allGameIds = gameIds;
      allGameIds.push(gameId);
      const updatedNfts = allNftsFromState.filter((item: any) =>
        allGameIds.includes(item?.appId),
      );
      setProcessedNfts(updatedNfts);
      setSelectAllFilter(true);
    }
    // all already selected remove from allGameIds
    else if (
      selectAllFilter &&
      gameId === 'all' &&
      clickedNftFilter.includes('all')
    ) {
      allGameIds = [...clickedNftFilter];
      const index = allGameIds.findIndex((item: any) => item === gameId);
      allGameIds.splice(index, 1);
      const updatedNfts = !allGameIds.length
        ? allNftsFromState
        : allNftsFromState.filter((item: any) =>
            allGameIds.includes(item?.appId),
          );
      allGameIds = [];
      setSelectAllFilter(false);
      setProcessedNfts(updatedNfts);
    }
    // add new selected game ids
    else if (gameId !== 'all' && !clickedNftFilter.includes(gameId)) {
      allGameIds = [gameId, ...clickedNftFilter];
      const updatedNfts = allNftsFromState.filter((item: any) =>
        allGameIds.includes(item?.appId),
      );
      setProcessedNfts(updatedNfts);
    }
    // deselect filters after clicking all
    else if (gameId !== 'all' && clickedNftFilter.includes(gameId)) {
      allGameIds = [...clickedNftFilter];
      allGameIds.forEach((item: any, i: number) => {
        if (item === gameId) {
          allGameIds.splice(i, 1);
        }
      });
      if (!selectAllFilter && allGameIds.includes('all')) {
        allGameIds.forEach((item: any, index: number) => {
          if (item === 'all') {
            allGameIds.splice(index, 1);
          }
        });
      }
      const updatedNfts: any = [];
      if (allGameIds.length) {
        allNftsFromState.forEach((item: any) => {
          if (allGameIds.includes(item.appId)) {
            updatedNfts.push(item);
          } else if (allGameIds.includes('all') && allGameIds.length === 1) {
            updatedNfts.push(item);
          }
        });
        setProcessedNfts(updatedNfts);
      } else {
        setProcessedNfts(allNftsFromState);
      }
    }
    // deselect filters after clicking all
    else if (gameId !== 'all' && !clickedNftFilter.includes(gameId)) {
      allGameIds = [...clickedNftFilter];
      if (!allGameIds.includes('all')) {
        const updatedNfts = allNftsFromState.filter((item: any) =>
          allGameIds.includes(item?.appId),
        );
        setProcessedNfts(updatedNfts);
      }
    } else {
      setProcessedNfts(allNftsFromState);
    }
    setActiveQuickFilter(allGameIds);
    setSelectedGameId(gameId);
    setFilterClick(allGameIds);
    setAllGameIds(allGameIds);
    collectiblesBIEvents.userClicksOnTopPrimaryAbstractions(
      utils.getGameName(gameId),
      nftIds,
      [],
      nftCount,
      0,
    );
  };

  const getSelectedFilters = (filters: any) => {
    setAppliedFilters([...filters]);
  };

  const deSelectFilter = (filter: any) => {
    setDeselectedFilters(filter);
  };

  const clearAll = () => {
    advanceFilterRef.current && advanceFilterRef.current.clearAll();
  };

  const handleCheckboxState = (event: any, allFilters: any) => {
    const allGameFilters: any = [...allFilters];

    allGameFilters.forEach((game: any) => {
      if (game.id === event.target.value)
        game.isSelected = event.target.checked;
    });
    setCheckboxfilter(allGameFilters);
  };

  const handleBlockchainFilter = (blockchainId: any) => {
    let allBlockChains: any = [];
    const allNftsFromState = [...copyProcessedNfts];
    const appliedGameFilters = [...clickedNftFilter];
    allBlockChains = [...clickedBlockchainFilter];
    if (!(clickedBlockchainFilter as any).includes(blockchainId)) {
      allBlockChains = [blockchainId, ...clickedBlockchainFilter];
      setClickedBlockchainFilter(allBlockChains);
    } else {
      const index = allBlockChains.findIndex(
        (item: any) => item === blockchainId,
      );
      allBlockChains.splice(index, 1);
      setClickedBlockchainFilter(allBlockChains);
    }
    const filterGames = appliedGameFilters.length
      ? allNftsFromState.filter((item: any) =>
          appliedGameFilters.includes(item.appId),
        )
      : allNftsFromState;
    const updatedNfts: any = filterGames.map((nft: any) => {
      return {
        ...nft,
        nfts: !allBlockChains.length
          ? nft.nfts
          : nft.nfts.filter((nftBlockchain: any) =>
              allBlockChains.includes(nftBlockchain.nft.blockchain),
            ),
      };
    });
    setProcessedNfts(updatedNfts);
  };

  const handleGenerationFilter = () => {
    const appliedGameFilters = [...clickedNftFilter];
    const allNftsFromState = [...copyProcessedNfts];
    if (appliedGameFilters.length) {
      let filterGames = [];
      filterGames = allNftsFromState.filter((item: any) =>
        appliedGameFilters.includes(item.appId),
      );
      return filterGames;
    }
  };

  const debounceHandleGenerationFilter = debounce(
    () => handleGenerationFilter(),
    100,
  );

  useEffect(() => {
    if (allNfts.length <= 0 || nftsDataSet.current) {
      return;
    }
    getNfts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNfts]);

  useEffect(() => {
    getNfts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`collectibles-main-wrp ${className}`}>
      <div className="collectibles-main-row d-flex" id="collectibles-main-page">
        {/* {isDomLoaded ? (
          <div
            className={expandSideBar ? 'expand' : 'collectibles-side-bar'}
            id="collec-sidebar"
          >
            <AdvanceFilter
              gametransformedData={processedNfts}
              setProcessedNfts={setProcessedNfts}
              setNftOnClearAll={copyProcessedNfts}
              rawGameData={allNfts}
              ref={advanceFilterRef}
              setFilterClick={setFilterClick}
              selectedGameId={setSelectedGameId}
              setActiveQuickFilter={setActiveQuickFilter}
              setClickedBlockchainFilter={setClickedBlockchainFilter}
              clickedBlockchainFilter={clickedBlockchainFilter}
              selecteQuickfilterId={selectedGameId}
              activeQuickFilter={activeQuickFilter}
              linkedAddress={!!linkedAddress}
              selectedFilters={getSelectedFilters}
              deSelectFilter={deselectedFilters}
              openSidebar={openSidebar}
              gameFilterClick={handleCheckboxState}
              listCheckboxFilters={checkboxFilters}
              handelGameFilter={handelGameFilter}
              handleBlockchainFilter={handleBlockchainFilter}
              handleGenerationFilter={debounceHandleGenerationFilter}
              nftIds={nftIds}
              nftCount={nftCount}
              allGameIds={allGameIds}
              // hideGames={true}
            />
          </div>
        ) : null} */}
        <div className={expandSideBar ? 'collapse' : 'collectibles-right-bar'}>
          <div className="filters-actions">
            {showAppliedFilters &&
            isDomLoaded &&
            clickedBlockchainFilter.length &&
            appliedFilters.length > 0 ? (
              <div className="applied-filters">
                <span className="title">Applied filters:</span>
                <div className="applied-filters-list">
                  {appliedFilters?.map((filter: any) => {
                    return (
                      <button className="filter-chips" key={filter.id}>
                        {filter.title}
                        <span
                          className="material-icons icon"
                          onClick={() => deSelectFilter(filter)}
                        >
                          close
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : (
              ''
            )}
            {isDomLoaded &&
            clickedBlockchainFilter.length &&
            appliedFilters.length > 0 ? (
              <button className="clear-btn" onClick={clearAll}>
                <u>Clear All</u>
              </button>
            ) : (
              ''
            )}
          </div>

          {showQuickFilters && (
            <div className="tab-content-champs">
              <div className="tab-pane active" id="champTab_1">
                <div className="nft-champ-main-rows">
                  <div className="tennis-champs-header d-flex align-items-center row">
                    {isDomLoaded &&
                    !clickedBlockchainFilter.length &&
                    !isMobile &&
                    linkedAddress &&
                    processedNfts?.length
                      ? filters.map((item: any, i: number) => (
                          <QuickFilters
                            name={item.name}
                            index={i}
                            id={item?.id}
                            key={'chip-' + i}
                            activeQuickFilter={activeQuickFilter}
                            handelClick={handelGameFilter}
                          />
                        ))
                      : isDomLoaded &&
                        !clickedBlockchainFilter.length &&
                        linkedAddress &&
                        processedNfts?.length
                      ? filters
                          .slice(0, 2)
                          .map((item, i) => (
                            <QuickFilters
                              name={item.name}
                              index={i}
                              id={item?.id}
                              key={'chip__' + i}
                              activeQuickFilter={activeQuickFilter}
                              handelClick={handelGameFilter}
                            />
                          ))
                      : ''}
                  </div>
                </div>
              </div>
            </div>
          )}
          {children}
        </div>

        {/* {!expandSideBar && (
          <div className="filter-button">
            <button
              className="filter"
              style={{
                color: appliedFilters.length > 0 ? '#ff006e' : '#ffffff',
              }}
              onClick={openSidebarWithBiEvent}
            >
              <img
                src={appliedFilters.length > 0 ? filterApplied : filter}
                alt="Filter Icon"
                className="filter-icon"
              />
              {appliedFilters.length > 0 ? 'Filter Applied' : 'Filter'}
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default FiltersLayout;

export function Head() {
  return <title>Superchamps Wallet | Racket Rampage</title>;
}
