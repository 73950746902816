import clsx from 'clsx';
import { navigate } from 'gatsby';
import React, { MouseEvent, useMemo } from 'react';

import slammerIcon from '@/assets/images/icons/icon-slammer.svg';
import superChampsImg from '@/assets/images/super-champs.png';
import tokenImg from '@/assets/images/tokenIcon.png';
// import rallyImg from '@/assets/images/rally-token.svg';
import { BI_CURRENCY, PLATFORM } from '@/bi/bi-constants';
import { collectiblesBIEvents } from '@/bi/events/collectibles-flow-bi-events';
import { marketPlaceBiEvents } from '@/bi/events/marketplace-flow-bi-events';
import utils, { isBrowser } from '@/common/utils';
import TokenIcon from '@/components/token-icon/token-icon';
import useUserId from '@/hooks/use-user-id';

import collectiblesUtils from '../../collectibles-utils';
import { pauseAllVideos } from '../../helpers';
import ListingTag from '../listing-tag/listing-tag';
import Traits from '../traits/traits';
import Video from '../video/video';

import * as styles from './champ-box.module.css';

const OPEN_SEA_ETH = 'https://opensea.io/assets/ethereum/';
const RARITY_VALUE = {
  COMMON: 0,
  UNCOMMON: 1,
  RARE: 2,
  EPIC: 3,
  LEGENDARY: 4,
};

type ChampBoxProps = {
  nft: any;
  type?: string;
  parentComponent: 'marketplace' | 'collectibles';
};

export default function ChampBox({
  nft,
  type,
  parentComponent,
}: ChampBoxProps) {
  const userId = useUserId();

  const nftPrice =
    nft?.marketplaceListingParamsList?.find((c: any) => c.currency === 'SLAM')
      ?.price || nft?.userMarketplaceListingDto?.price.toFixed(2);

  const minMaxSlots = useMemo(() => {
    if (nft?.slots) {
      const usedSlots = nft?.slots.filter((item: any) => item.isUsed);
      return usedSlots.length + '/' + nft?.maxSlotsCount;
    } else {
      return 0 + '/' + nft?.maxSlotsCount;
    }
  }, [nft?.slots, nft?.maxSlotsCount]);

  const isUserListing = userId === nft?.userMarketplaceListingDto?.userId;

  const markeplaceCut = useMemo(() => {
    const originalPrice = collectiblesUtils.calculateMarketPlaceFee(
      nft?.userMarketplaceListingDto?.price,
      nft,
    ).price;
    return collectiblesUtils.marketPlaceFeesOnly(originalPrice, nft);
  }, [nft]);

  const royaltyCut = useMemo(() => {
    const originalPrice = collectiblesUtils.calculateMarketPlaceFee(
      nft?.userMarketplaceListingDto?.price,
      nft,
    ).price;
    return collectiblesUtils.royaltyFeesOnly(originalPrice, nft);
  }, [nft]);

  const champImgUrl = (displayUrl: any) => {
    try {
      const u = new URL(displayUrl);
      return u;
    } catch (err) {
      const urlRegex = /(https?:\/\/[^ ]*)/;
      const url = displayUrl.match(urlRegex)[1];
      return parentComponent === 'collectibles'
        ? url.slice(0, url.length - 1)
        : url.slice(0, url.length);
    }
  };

  // const tokenImgUrl = useMemo(
  //   () =>
  //     nft?.userMarketplaceListingDto?.marketplace !== 'OPENSEA'
  //       ? rallyImg
  //       : ethTokenImg,
  //   [nft],
  // );

  const handleClick = (e?: MouseEvent<HTMLDivElement>) => {
    e?.preventDefault();
    e?.stopPropagation();

    const tempNft = {
      ...nft,
      minMaxSlots,
      isListingTag: isUserListing,
      parentComponent,
      markeplaceFee: parentComponent === 'marketplace' ? markeplaceCut : -1,
      royaltyFee: parentComponent === 'marketplace' ? royaltyCut : -1,
      biPrice:
        parentComponent === 'marketplace'
          ? collectiblesUtils.calculateMarketPlaceFee(
              nft?.userMarketplaceListingDto?.price,
              nft,
            ).price
          : -1,
      rarityValue: RARITY_VALUE[nft.nft?.rarity],
    };

    pauseAllVideos();

    navigate('/collectibles/details', { state: { nft: tempNft } });
    collectiblesBIEvents.userClicksOnNFTsMintPasses(
      'nft',
      utils.getGameName(nft.appId),
      nft.nft.type,
      nft.appId,
      nft.nft?.display?.name,
      nft.nft.nftIndex + '_' + nft.nft.contractId + '_' + nft.nft.blockchain,
      nft.nft.nftIndex,
      nft.nft.contractId,
      nft.nft.blockchain,
    );

    if (parentComponent === 'marketplace') {
      marketPlaceBiEvents.userClicksOnNFTMarketplace(
        nft?.userMarketplaceListingDto?.marketplace,
        nft?.nft?.nftIndex,
        nft?.nft?.contractId,
        nft?.nft?.blockchain,
        nft?.nft?.generation === 0 ? 'genesis' : nft?.nft?.type,
        nft?.currentattributes,
        nft.nft?.traits?.find((a) => a.name === 'Style')?.value,
        nft.nft?.traits,
        nft?.userMarketplaceListingDto?.currency,
        RARITY_VALUE[nft.nft?.rarity],
        nft.currentLevelXp,
        nft.nextLevelXp,
        nft.level,
        collectiblesUtils.calculateMarketPlaceFee(
          nft?.userMarketplaceListingDto?.price,
          nft,
        ).price,
        markeplaceCut,
        royaltyCut,
      );
    } else {
      //TODO: verify this event
      marketPlaceBiEvents.userClicksOnNFTOnCollectibles(
        PLATFORM,
        nft?.nft?.nftIndex,
        nft?.nft?.contractId,
        nft?.nft?.blockchain,
        nft?.nft?.generation === 0 ? 'genesis' : nft?.nft?.type,
        nft?.currentattributes,
        nft.nft?.traits?.find((a) => a.name === 'Style')?.value,
        nft.nft?.traits,
        BI_CURRENCY,
        RARITY_VALUE[nft.nft?.rarity],
        nft.currentLevelXp,
        nft.nextLevelXp,
        nft.level,
      );
    }
  };

  const handleMoreInfoClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (nft?.userMarketplaceListingDto?.marketplace === 'OPENSEA') {
      const url = `${OPEN_SEA_ETH}${
        nft?.nft?.contractId + '/' + nft?.nft?.nftIndex
      }`;
      return isBrowser() ? window.open(url, '_blank') : null;
    }

    handleClick();
  };

  return (
    <div
      className={clsx(
        'store-item joyrideChampBox col-xs-12 pb-10',
        styles.container,
      )}
      onClick={handleClick}
    >
      {/* <div className={styles.topInfo}>
        <span className="champ-top-code purple">Recruit: {minMaxSlots}</span>
      </div> */}
      {nft.nft.generation === 0 && !nft.nft.tempOwnerUserId && (
        <TokenIcon iconWidth={30} nft={nft} tokenImage={tokenImg} />
        // <div className="eth-icon">
        // </div>
      )}
      {nft.nft.generation === 0 && nft.nft.tempOwnerUserId && (
        <div className="eth-icon">
          <TokenIcon iconWidth={30} nft={nft} tokenImage={superChampsImg} />
        </div>
      )}
      {nft.nft.blockchain !== 'FLOW' && nft.nft?.media?.[0]?.file ? (
        <Video src={nft.nft.media[0].file}>
          {isUserListing && (
            <ListingTag>
              {parentComponent === 'marketplace'
                ? 'Your Listing'
                : 'Listed for Sale'}
            </ListingTag>
          )}
        </Video>
      ) : (
        <div className="store-item-image-holder">
          <img
            className={styles.nftThumbnail}
            src={
              nft.nft.display.thumbnail
                ? nft.nft.blockchain === 'FLOW'
                  ? champImgUrl(
                      nft.nft.display.thumbnail.replace('.png', '_T.png'),
                    )
                  : champImgUrl(nft.nft.display.thumbnail)
                : ''
            }
            alt={nft.nft.display.name}
          />
          <div className="mt-3"></div>
        </div>
      )}

      <h5 className="champ-name">{nft.nft.display.name}</h5>
      <div className="flex aic jcc g-1">
        <Traits
          nft={nft}
          traitsWidth={'trait-champ-width'}
          background={'#454F87'}
          parentComponent={'listing'}
        />
      </div>

      {parentComponent === 'marketplace' && (
        <div className="champ-price-direction mt-2">
          <div className="champ-price-label">Price:</div>
          <div className={`${styles.priceContainer} champ-price-container`}>
            <img src={slammerIcon} width={35} alt="Slammer" />
            <span className="champ-price">{nftPrice}</span>
          </div>
        </div>
      )}

      <div>
        <button className="white-hover-button" onClick={handleMoreInfoClick}>
          {type === 'assets'
            ? nft.nft.generation === 0 && nft.nft.tempOwnerUserId
              ? 'List for Sale'
              : 'More Info'
            : 'Buy Now'}
        </button>
      </div>
    </div>
  );
}
