import React, { FC, useEffect, useState } from 'react';

import ApiUser from '@/api/user';
import gameDownloadIcon from '@/assets/images/game-download.svg';
import { ECtaNames, EWebsiteTabNames } from '@/bi/bi-constants';
import { commonBiEvents } from '@/bi/events/common-bi-events';
import { viewWebsiteTabsBiEvents } from '@/bi/events/view-website-tabs-bi-events';
import utils, { isBrowser } from '@/common/utils';
import Banner from '@/components/banner';
import { useAuth } from '@/context/auth-context';

import { gamesAndDownloadUrls } from '../helpers';

import ChampBox from './champ-box/champ-box';
import { CountCollectibles } from './count';

const gameIds = ['onjoyride.tennischamps'];
let count = 0;

export interface ICollectiblesList {
  hideGameHeaders?: boolean;
  hideCollectiblesBanner?: boolean;
  type?: string;
}

const CollectiblesList: FC<ICollectiblesList> = ({
  hideGameHeaders,
  hideCollectiblesBanner,
  type,
}) => {
  const { user } = useAuth();
  // const [allNfts, setAllNfts] = useState<[]>([]);
  const [nftIds, setNftIds] = useState('');
  const [nftCount, setNftCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState([0]);
  // const [copyProcessedNfts, setCopyProcessedNfts] = useState([]);
  const [processedNfts, setProcessedNfts] = useState([]);
  // const [selectedMintPass, setSelectedMintPass] = useState(null);
  // console.log('🚀 ~ CollectiblesList ~ selectedMintPass:', selectedMintPass);
  const [isDomLoaded, setLoadDom] = useState(false);

  const getDownloadUrlOfGame = (gameId: any) => {
    const game = gamesAndDownloadUrls.find((item) => item.id === gameId);
    return game?.url || '';
  };

  const handleMultipleChamps = (champs: any[]) => {
    const allGames: any = [];
    // get all unique game-ids
    gameIds.forEach((id: any) => {
      const nfts: any =
        champs?.filter(
          (item) => item.characterType === 'NFT' && item?.appId === id,
        ) || [];
      const mintPass: any =
        champs?.filter(
          (item) => item.characterType === 'MINT_PASS' && item?.appId === id,
        ) || [];
      allGames.push({
        appId: id,
        nfts,
        mintPass,
        url: getDownloadUrlOfGame(id),
      });
    });
    utils.sortGames(allGames);
    const games: any[] = [];
    const nftIds: any[] = [];
    let nftsCount = 0;
    allGames.forEach((game: any) => {
      games.push(utils.getGameName(game.appId) + '(' + game.nfts.length + ')');
      game.nfts.forEach((nft: any) => {
        nftIds.push(
          nft.nft.nftIndex +
            '_' +
            nft.nft.contractId +
            '_' +
            nft.nft.blockchain,
        );
      });
      setNftIds(nftIds.toString());
      nftsCount = nftsCount + game.nfts.length;
      setNftCount(nftsCount);
    });
    viewWebsiteTabsBiEvents.userIsOnSomeTab(
      EWebsiteTabNames.COLLECTIBLES,
      (games as any).toString(),
      (nftIds as any).toString(),
      null as any,
      nftsCount,
      0,
    );
    setProcessedNfts(allGames);
    // setCopyProcessedNfts(allGames);
  };

  const getNfts = async () => {
    try {
      if (!user) {
        return;
      }
      const data = await ApiUser.getNftsForUser(user.appUser.user.id);
      // setAllNfts(data);
      handleMultipleChamps(data);
      setLoadDom(true);
    } catch (e) {
      // BI event maybe
      console.error('getNfts error');
    }
  };

  // const mintPassDetailAction = (mintPass: any) => {
  //   // setSelectedMintPass(mintPass);
  //   const collectiblesMainPage = document.getElementById(
  //     'collectibles-main-page',
  //   );
  //   if (collectiblesMainPage) {
  //     collectiblesMainPage.classList.add('hide-section');
  //   }
  // };

  const handleGameCardToggle = (id: any) => {
    const index = activeIndex.indexOf(id);
    if (index > -1) {
      const item = [...activeIndex];
      item.splice(index, 1);
      setActiveIndex(item);
    } else {
      const addItem = [...activeIndex];
      addItem.push(id);
      setActiveIndex(addItem);
    }
  };

  const handleDownload = (url: string, gameName: string) => {
    const secondaryAbstractions: any = [];
    const filteredGame = processedNfts.filter(
      (game: any) => utils.getGameName(game.appId) === gameName,
    );
    secondaryAbstractions.push('Mint Pass(0)');
    secondaryAbstractions.push(
      gameName === 'Tennis Champs' || gameName === 'Battle Champs'
        ? 'Champs' + '(' + (filteredGame[0] as any).nfts.length + ')'
        : 'Blitz' + '(' + (filteredGame[0] as any).nfts.length + ')',
    );
    if (isBrowser()) {
      window.open(url, '_blank');
    }
    commonBiEvents.userClicksOnSomeCta(
      EWebsiteTabNames.COLLECTIBLES,
      ECtaNames.DOWNLOAD_REDIRECTION,
      gameName,
      'game_landing_page',
      secondaryAbstractions.toString(),
      nftIds,
      '',
      nftCount,
      0,
    );
  };

  const handleBuyNowBannerClick = (appId: string) => {
    if (appId === 'onjoyride.tennischamps') {
      const url = 'https://opensea.io/collection/tennis-champs-genesis';
      if (isBrowser()) {
        window.open(url, '_blank');
      }
    } else {
      const url = 'https://opensea.io/joyridegames?tab=created';
      if (isBrowser()) {
        window.open(url, '_blank');
      }
    }
  };

  const handleBuyNowBannerText = (appId: string, nfts: []) => {
    if (appId === 'onjoyride.tennischamps' && !nfts.length) {
      return ["Play the world's first tennis RPG NFTs"];
    } else {
      return ['You can buy Champ NFTs ', 'from our marketplace'];
    }
  };

  useEffect(() => {
    getNfts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`joyrideChampRow game-card-mobile ${
        isDomLoaded ? 'joyrideChampBorder' : ''
      }`}
    >
      {isDomLoaded &&
        processedNfts &&
        processedNfts.map(({ nfts, mintPass, appId, url }: any, i: number) => {
          return (
            <div className="card game-card-container" key={'nft-id-' + i}>
              {!hideGameHeaders && (
                <div
                  className="game-slider"
                  onClick={() => handleGameCardToggle(i)}
                >
                  <div
                    className="card-header-back game-name"
                    data-toggle="collapse"
                    data-target={`collapseExample${i}`}
                  >
                    <span
                      className="collectibles-card-font"
                      style={{ display: 'inline', fontWeight: 500 }}
                    >
                      {utils.getGameName(appId)}
                    </span>
                    <CountCollectibles
                      countNumber={nfts?.length + mintPass.length}
                    />
                    {url && (
                      <span
                        className="download-icon"
                        onClick={() =>
                          handleDownload(url, utils.getGameName(appId))
                        }
                      >
                        <img
                          src={gameDownloadIcon}
                          width="20"
                          height="20"
                          alt="game-download-icon"
                        />
                      </span>
                    )}
                  </div>

                  <span
                    style={{ fontSize: '35px' }}
                    className={
                      (activeIndex.includes(i) ? 'rotate-arrow ' : '') +
                      'material-icons-outlined'
                    }
                  >
                    chevron_right
                  </span>
                </div>
              )}
              {activeIndex.includes(i) ? (
                <>
                  {/* TODO: Uncomment minst passes UI below when available in api's */}

                  {mintPass.length ? (
                    <>
                      <div className="flex mint-pass-text">
                        <span className="champs game-name">Recruit Pass</span>
                        <CountCollectibles countNumber={mintPass.length} />
                      </div>
                      <div className="row champ-container pr-md-4 mint-container">
                        {mintPass.map((item: any, i: number) => (
                          <img
                            src="https://champs-metadata.onjoyride.com/tc-recruits/media/recruit_pass_square.jpg"
                            key={`mint-pass-${i}`}
                            className="joyrideChampBox col-xs-12 mint-pass-main"
                            // onClick={() => mintPassDetailAction(item)}
                            alt="mint pass"
                          ></img>
                        ))}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  {nfts.length ? (
                    <div className="flex mint-pass-text">
                      <span className="champs game-name">Champs</span>
                      <CountCollectibles countNumber={nfts?.length} />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="row champ-container pr-md-4">
                    {nfts?.length
                      ? utils.sortNfts(nfts)?.map((nft) => {
                          if (nft.nft?.media && nft.nft?.media[0]?.file) {
                            if (count === 0) {
                              count++;
                            }
                          }
                          return (
                            <ChampBox
                              key={nft.id}
                              nft={nft}
                              type={type}
                              parentComponent={'collectibles'}
                            />
                          );
                        })
                      : null}
                  </div>
                  {!hideCollectiblesBanner && (
                    <div
                      key={'banner-' + i}
                      className="explore-Joyride-Ecosystem-wrp champs-banner-margin collectibles-banner"
                    >
                      <Banner
                        handleClick={() => handleBuyNowBannerClick(appId)}
                        pageType={'collectibles-list'}
                        buttonText={'Buy Now'}
                        text={handleBuyNowBannerText(appId, nfts)}
                      />
                    </div>
                  )}
                </>
              ) : activeIndex.includes(i) &&
                !hideCollectiblesBanner &&
                (!nfts.length || nfts.length - 1 === i) ? (
                <div
                  key={'banner-' + i}
                  className="explore-Joyride-Ecosystem-wrp champs-banner-margin collectibles-banner"
                >
                  <Banner
                    handleClick={() => handleBuyNowBannerClick(appId)}
                    pageType={'collectibles-list'}
                    buttonText={'Buy Now'}
                    text={handleBuyNowBannerText(appId, nfts)}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      <hr />
      <hr />
    </div>
  );
};

export default CollectiblesList;
