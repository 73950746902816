import React, { Fragment } from 'react';

interface BannerProps {
  text: React.ReactNode[];
  pageType?: string;
  handleClick: () => void;
  buttonText: string;
}

function Banner({ text, handleClick, buttonText, pageType }: BannerProps) {
  return (
    <div
      className={`explore-Joyride-Ecosystem-box ${
        pageType === 'collectibles-list'
          ? 'collectibles-banner-img justify-content-end'
          : ''
      }`}
    >
      <p
        style={{ fontSize: '22px' }}
        className={pageType === 'collectibles-list' ? 'banner-text' : ''}
      >
        <span className={pageType === 'collectibles-list' ? 'banner-msg' : ''}>
          {text.map((item, i) => (
            <Fragment key={i}>{item}</Fragment>
          ))}
        </span>
        <button
          className="theme-btn white-bg-btn Withdraw d-block mt-4 mx-auto"
          onClick={handleClick}
        >
          {buttonText}
        </button>
      </p>
    </div>
  );
}

export default Banner;
