import React, { useEffect } from 'react';

import '@/assets/scss/racket-rampage.scss';
import utils from '@/common/utils';
import { withDashboardLayout } from '@/components/layout/dashboard-layout';
import FiltersLayout from '@/components/layout/filters-layout';
import { useAuth } from '@/context/auth-context';
import CollectiblesList from '@/features/collectibles-marketplace/components/collectibles-list';
import NavPills from '@/features/racket-rampage/nav-pills';
import RewardEvent from '@/features/racket-rampage/reward-event';

const RRAssets = () => {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      utils.getAuthUrl();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FiltersLayout className="racket-rampage">
        <>
          <RewardEvent />
          <NavPills app="marketplace" />
          <CollectiblesList
            type="assets"
            hideGameHeaders={true}
            hideCollectiblesBanner={true}
          />
        </>
      </FiltersLayout>
    </>
  );
};

export default withDashboardLayout(RRAssets);

export function Head() {
  return <title>Superchamps Wallet | Racket Rampage</title>;
}
